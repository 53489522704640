/** Content list lane */
export const CONTENT_LIST_LANE = 'content-list-lane';
export const createContentListLinkGroupTestHook = (label, index) => `content-list-lane-link-${label}-${index + 1}`;
/** FAQ lane */
export const FAQ_LANE = 'faq-lane';
export const FAQ_LANE_QUESTION = 'faq-lane-question';
export const FAQ_LANE_ANSWER = 'faq-lane-answer';
export const FAQ_LANE_SHOW_MORE = 'faq-lane-show-more';
/** Image card collection lane */
export const IMAGE_CARD_COLLECTION_LANE_LEFT_BOTTOM_CARD = 'image-card-collection-lane-left-bottom-image-card';
export const IMAGE_CARD_COLLECTION_LANE_RIGHT_BOTTOM_CARD = 'image-card-collection-lane-right-bottom-image-card';
export const IMAGE_CARD_COLLECTION_LANE_BANNER_CARD = 'image-card-collection-lane-banner-image-card';
export const IMAGE_CARD_COLLECTION_LANE_INNER_IMAGE_TEXT = 'image-card-collection-lane-inner-image-text';
export const IMAGE_CARD_COLLECTION_LANE_OUTER_IMAGE_TEXT = 'image-card-collection-lane-outer-image-text';
export const IMAGE_CARD_COLLECTION_LANE_LINK_WITH_LABEL = 'image-card-collection-lane-link-with-label';
export const IMAGE_CARD_COLLECTION_LANE_LINK_WITHOUT_LABEL = 'image-card-collection-lane-link-without-label';
export const IMAGE_CARD_COLLECTION_LANE_LINK_NO_LINK_TEXT = 'image-card-collection-lane-no-link-text';
/** Product lane */
export const PRODUCT_LANE = 'product-lane';
export const PRODUCT_LANE_PRODUCT_CARD = 'product-lane-product-card';
/** Recipe grid lane */
export const RECIPE_GRID_LANE = 'recipe-grid-lane';
export const RECIPE_GRID_LANE_PLACEHOLDER = 'recipe-grid-lane-placeholder';
export const RECIPE_GRID_LANE_TITLE = 'recipe-grid-lane-title';
export const RECIPE_GRID_LANE_SUBTITLE = 'recipe-grid-lane-subtitle';
/** Recipe group header lane */
export const RECIPE_GROUP_HEADER_LANE = 'recipe-group-header-lane';
export const RECIPE_GROUP_HEADER_LANE_ANCHORS = 'recipe-group-header-lane-anchors';
export const createRecipeGroupHeaderLaneAnchorTestHook = (anchorHref) => `recipe-group-header-lane-anchors-${anchorHref.substring(1)}`;
export const RECIPE_GROUP_HEADER_LANE_LINK = 'recipe-group-header-lane-link';
/** Search banner lane */
export const SEARCH_BANNER_LANE = 'search-banner-lane';
export const SEARCH_BANNER_LANE_BOTTOM = 'search-banner-lane-bottom';
export const SEARCH_BANNER_LANE_IMAGE = 'search-banner-lane-image';
export const SEARCH_BANNER_LANE_QUICK_ENTRIES = 'search-banner-lane-quick-entries';
export const createSearchBannerQuickEntryLink = (label) => `search-banner-lane-quick-entry-${label}`;
export const SEARCH_BANNER_LANE_RECIPE_LINK = 'search-banner-lane-recipe-link';
export const SEARCH_BANNER_LANE_SUGGESTION_ITEM = 'search-banner-lane-suggestion-item';
/** Spotlight lane */
export const SPOTLIGHT_LANE = 'spotlight-lane';
export const SPOTLIGHT_LANE_CTA = 'spotlight-lane-cta';
/** Text image items content lane */
export const TEXT_IMAGE_ITEMS_CONTENT_LANE = 'text-image-items-content-lane';
/** Theme lane */
export const THEME_LANE = 'theme-lane';
export const THEME_LANE_CATEGORY_CARD = 'category-card';
/** Theme list card collection lane */
export const createThemeListCardCollectionLaneCard = (index) => `theme-list-card-collection-${index}`;
export const createThemeListCardCollectionLaneCardLink = (label) => `theme-list-card-collection-link-${label}`;
export const THEME_LIST_CARD_COLLECTION_LANE_CTA = 'theme-list-card-collection-cta';
export const THEME_LIST_CARD_COLLECTION_LANE_TARGETED_SCROLL_ICON = 'theme-list-card-collection-targeted-scroll-icon';
export const THEME_LIST_CARD_COLLECTION_LANE_EXTERNAL_LINK_ICON = 'theme-list-card-collection-external-link-icon';
/** Video lane */
export const VIDEO_LANE_INFO = 'video-lane-info';
/** Week menu lane */
export const WEEK_MENU_LANE = 'weekmenu-lane';
/** Food preferences */
export const FOOD_PREFERENCES_ERROR = 'food-preferences-loading-error-message';
export const FOOD_PREFERENCES_ERROR_TITLE = 'page-error-title';
export const FOOD_PREFERENCES_ERROR_MESSAGE = 'page-error-message';
export const FOOD_PREFERENCES_SELECTION = 'food-preferences-selection';
export const FOOD_PREFERENCES_REGISTER_BUTTON = 'login-button-register';
export const FOOD_PREFERENCES_LOGIN_BUTTON = 'login-button-login';
export const FOOD_PREFERENCES_SKELETON_LOADER = 'food-preferences-selection-loader';
export const FOOD_PREFERENCES_DELETE_BUTTON = 'delete-food-preferences-button';
export const FOOD_PREFERENCES_DELETE_MODAL = 'delete-preferences-modal-root';
export const FOOD_PREFERENCES_DELETE_MODAL_TEXT = 'food-preferences-delete-preferences-modal-text';
export const FOOD_PREFERENCES_DELETE_MODAL_CONFIRM = 'food-preferences-delete-proceed-button';
export const FOOD_PREFERENCES_DELETE_MODAL_CANCEL = 'food-preferences-delete-cancel-button';
export const FOOD_PREFERENCES_DIET_FORM = 'food-preferences-diet-form-container';
export const FOOD_PREFERENCES_DIET_FORM_SAVE_BUTTON = 'save-food-preferences-button';
export const FOOD_PREFERENCES_FOOD_FORM = 'food-preferences-food-form-container';
export const FOOD_PREFERENCES_FOOD_FORM_SAVE_BUTTON = 'save-food-preferences-button';
export const FOOD_PREFERENCES_FOOD_FORM_BACK_BUTTON = 'back-food-preferences-button';
export const createFoodPreferencesIngredientChip = (option) => `food-preferences-question-chip-${option}`;
export const FOOD_PREFERENCES_ADD_INGREDIENT_BUTTON = 'create-ingredient-chip-plus';
export const FOOD_PREFERENCES_INGREDIENT_MODAL = 'ingredient-preferences-modal-root';
export const FOOD_PREFERENCES_INGREDIENT_MODAL_UNWANTED_INPUT = 'unwanted-ingredient-input';
export const FOOD_PREFERENCES_INGREDIENT_MODAL_SAVE_BUTTON = 'food-preferences-save-button';
/** Search */
export const SEARCH_INPUT = 'search-input';
export const SEARCH_BUTTON = 'search-button';
export const SEARCH_SUGGESTIONS = 'search-suggestions';
export const SEARCH_SORT = 'search-sort';
export const SEARCH_FILTERS_GROUP_LIST = 'search-filters-list';
export const SEARCH_FILTERS_GROUP_ITEM = 'search-filters-item';
export const SEARCH_FILTERS_GROUP_MORE_BUTTON = 'search-filters-more-button';
export const SEARCH_FILTERS_MOBILE_OPEN_BUTTON = 'search-filters-mobile-open-button';
export const SEARCH_FILTERS_MOBILE_APPLY_BUTTON = 'search-filters-mobile-apply-button';
export const SEARCH_RESULTS_HEADING = 'search-results-heading';
export const SEARCH_RESULTS_NO_RESULTS = 'search-results-no-results';
export const SEARCH_PAGINATION = 'search-pagination';
export const SEARCH_PAGINATION_PLACEHOLDER = 'search-pagination-placeholder';
export const SEARCH_PAGINATION_PREVIOUS = 'search-pagination-previous';
export const SEARCH_PAGINATION_NEXT = 'search-pagination-next';
/** Person counter */
export const PERSON_COUNTER_MINUS = 'person-counter-chip-minus';
export const PERSON_COUNTER_PLUS = 'person-counter-chip-plus';
export const PERSON_COUNTER_TEXT = 'person-counter-text';
/** Recipe */
export const RECIPE_HEADER_IMAGE = 'header-image';
export const RECIPE_HEADER_TITLE = 'header-title';
export const RECIPE_HEADER_SUBTITLE = 'header-subtitle';
export const RECIPE_HEADER_ENERGY = 'header-energy';
export const RECIPE_HEADER_TIME = 'header-time';
export const RECIPE_HEADER_SPICINESS = 'header-spiciness';
export const RECIPE_HEADER_RATING = 'header-rating';
export const RECIPE_HEADER_RATING_COUNT = 'header-rating-count';
export const RECIPE_HEADER_TAGS = 'header-tags';
export const RECIPE_HEADER_FAVORITE_BUTTON = 'header-favorite-button';
export const RECIPE_TAG = 'recipe-tag';
export const RECIPE_INGREDIENTS = 'ingredients';
export const RECIPE_INGREDIENTS_SHOP_BUTTON = 'shop-ingredients-button';
export const RECIPE_APPLIANCES = 'appliances';
export const RECIPE_STEPS_PREPARATION = 'preparation-steps';
export const RECIPE_TIPS = 'preparation-tips';
export const RECIPE_RATING = 'recipe-preparation-rating';
export const RECIPE_RATING_STAR = 'recipe-preparation-star';
export const RECIPE_RELATED_RECIPE_GRID = 'related-recipes-grid';
export const RECIPE_FOOTER_IMAGE = 'footer-image';
export const RECIPE_FOOTER_NUTRITION = 'footer-nutrition';
export const RECIPE_FOOTER_TAGS = 'footer-tags';
export const RECIPE_PRINT_PREPARATION_BUTTON = 'recipe-preparation-control-button';
export const RECIPE_PRINT_INGREDIENTS = 'print-ingredients';
export const RECIPE_PRINT_KITCHEN = 'print-ingredients-kitchen';
export const RECIPE_PRINT_STEPS = 'print-steps';
export const RECIPE_PRINT_TIPS = 'print-tips';
/** Breadcrumbs */
export const BREADCRUMBS = 'breadcrumbs';
export const BREADCRUMBS_BACK = 'breadcrumbs-back';
export const BREADCRUMBS_ITEM = 'breadcrumbs-item';
/** Inline video */
export const INLINE_VIDEO = 'inline-video';
/** Video play button */
export const VIDEO_PLAY_BUTTON = 'video-play-button';
/** Modal */
export const MODAL_CLOSE_BUTTON = 'modal-close';
/** My recipes */
export const MY_RECIPES_SETTINGS_BUTTON = 'collection-category-settings-button';
export const MY_RECIPES_DELETE = 'collection-category-options-delete';
export const MY_RECIPES_DELETE_CONFIRM = 'collection-category-delete-confirm';
export const MY_RECIPES_RENAME = 'collection-category-options-edit';
export const MY_RECIPES_RENAME_INPUT = 'collection-category-options-edit-input-field';
export const MY_RECIPES_RENAME_CONFIRM = 'collection-category-update-confirm';
