'use client';

import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { ChevronUp16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-up-16';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import { useScroll } from '@royalaholddelhaize/design-system-pantry-web/hooks/use-scroll/use-scroll';
import classNames from 'clsx';
import type { FC, PropsWithChildren } from 'react';

import css from './back-to-top.module.scss';

const SCROLL_OFFSET_TRIGGER = 500;

export const BackToTop: FC<PropsWithChildren> = () => {
    const { scrollY } = useScroll({});
    const { t } = useTranslations();

    const handleOnClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <button
            className={classNames(
                css.root,
                scrollY > SCROLL_OFFSET_TRIGGER && css.visible,
            )}
            type="button"
            onClick={handleOnClick}
            aria-label={t('backToTop.label')}
        >
            <ChevronUp16Icon size={18} className={css.icon} />
            <Typography className={css.text} variant="button">
                {t('backToTop.title')}
            </Typography>
        </button>
    );
};
